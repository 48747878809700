<template>
  <div class="sign">
    <div class="sign__container">
      <p class="sign__welcome">
        С возвращением
      </p>
      <h2 class="sign__title">
        Вход в учётную запись
      </h2>
      <div
        v-if="GET_POLITICS_ERROR"
        class="sign__error"
      >
        <div class="sign__error-icon">
          <VIconError />
        </div>

        <span>Для продолжения работы с&nbsp;сервисом, вам&nbsp;необходимо
          согласиться с&nbsp;документом</span>
      </div>
      <Form
        v-slot="{ errors }"
        class="sign__form"
        @submit="checkForm"
      >
        <!-- E-mail -->
        <vInput
          id="email"
          v-model="email"
          :value="email"
          placeholder="E-mail"
          rule="required|mail|max:254"
          :required="true"
          :error="USER_ERROR"
        />

        <!-- Пароль -->
        <vInput
          id="password"
          v-model="password"
          type="password"
          :value="password"
          placeholder="Пароль"
          :required="true"
          rule="min:6"
          :block-paste="false"
        />

        <router-link
          to="/users/forget"
          class="sign__forget-pass"
        >
          Забыли пароль?
        </router-link>

        <vButton
          type="submit"
          :disabled="
            Object.keys(errors).length > 0 ||
              email.length < 1 ||
              password.length < 1
          "
        >
          Войти
        </vButton>
        <p class="sign__text">
          Не зарегистрированы?
          <router-link
            to="/users/registration"
            class="sign__text-link"
          >
            Регистрация
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.33325 0.666641L10.6666 3.99998M10.6666 3.99998H1.33325H10.6666ZM7.33325 7.33331L10.6666 3.99998L7.33325 7.33331Z"
                stroke="#6764FF"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </router-link>
        </p>
      </Form>
      <a href="/">
        <img
          src="~@/assets/images/logo.svg"
          class="sign__logo"
          alt="logo"
        >
      </a>
      <p class="sign__copyright">
        &copy; powered by tapper 2021
      </p>
    </div>
  </div>
</template>

<script>
import { Form } from 'vee-validate'
import { mapGetters, mapActions } from 'vuex'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'
import VIconError from '@/assets/images/icons/v-icon-error'
export default {
  name: 'UserPage',
  components: {
    vInput,
    vButton,
    Form,
    VIconError,
  },
  data() {
    return {
      email: '',
      password: '',
    }
  },
  computed: {
    ...mapGetters('user', ['USER_ERROR', 'GET_AUTHENTIFICATED', 'USER_DATA']),
    ...mapGetters('legalDocuments', ['GET_POLITICS_ERROR'])
  },
  watch: {
    email() {
      if (this.USER_ERROR.length > 0) {
        this.ACTION_CLEAR_USER_ERRORS()
      }
    },
  },
  mounted() {
    this.TOGGLE_PRELOADER(false)
  },
  methods: {
    ...mapActions('user', [
      'SIGNIN',
      'ACTION_CLEAR_USER_ERRORS',
      'GET_USER_DATA',
      'LOGOUT',
    ]),
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('legalDocuments', [
      'TOGGLE_POLITICS_ERROR',
    ]),

    async checkForm() {
      this.TOGGLE_PRELOADER(true)
      this.TOGGLE_POLITICS_ERROR(false)
      await this.SIGNIN({ email: this.email, password: this.password })
      if (this.USER_ERROR.length < 1) {
        await this.GET_USER_DATA(this.GET_AUTHENTIFICATED)
        this.navToProfile()
      } else {
        this.TOGGLE_PRELOADER(false)
      }
    },

    navToProfile() {
      this.$router.push('/profile')
      this.email = ''
      this.password = ''
    },
  },
}
</script>
