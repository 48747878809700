<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 17V12H11M11.9119 8.05972C11.9659 8.10559 12 8.17383 12 8.25C12 8.29461 11.9883 8.3365 11.9678 8.37276M11.9119 8.05972C11.8681 8.02249 11.8113 8 11.749 8C11.6772 8 11.6125 8.03028 11.5671 8.07874M11.9119 8.05972L11.749 8.45312L11.5671 8.07874M11.5671 8.07874C11.5251 8.12349 11.4995 8.18375 11.5 8.25C11.5 8.29461 11.5117 8.3365 11.5322 8.37276M11.5322 8.37276C11.5751 8.4487 11.6566 8.5 11.75 8.5C11.8434 8.5 11.9249 8.4487 11.9678 8.37276M11.5322 8.37276L11.75 8.25L11.9678 8.37276M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
      stroke="#EC4E4E"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconError',
}
</script>

<style lang="scss"></style>
